.id {
    color: #cbcbcb;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    vertical-align: middle;
    backface-visibility: hidden;
    position: relative;
    max-width: 100%;
    text-align: center;

    &--img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: auto;
        z-index: inherit;
        max-width: 100%;
        max-height: 100%;
    }

    &--heroimg {
        max-width: 100%;
        max-height: 100%;
        border-radius:24px;
    }

    &--placeholder {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        margin: auto;
        z-index: inherit;
        font: bold 16px/1.4 "Helvetica Neue",Helvetica,Arial,sans-serif;
        height: 1em;
        text-transform: uppercase;
        font-size: .55em;
        line-height: 1em;
    }

    &__logo {
        overflow: hidden;
        border: 1px solid #cbcbcb;
        background-color: #fff;


        &:hover {
            box-shadow: 0 2px 6px -1px rgba(45,45,45,.2);
        }

        &:before {
            position: relative;
            display: block;
            padding-top: 100%;
            height: 0;
            content: '';
            background-image: linear-gradient(rgba(45,45,45,0),rgba(45,45,45,.02));
            background-color: rgba(45,45,45,.02);
            border-radius: 8%;
        }
    }
}

.ribbon {
    transform: rotate(45deg);
    position: absolute;
    top: 10px;
    right: -55px;
    font-weight: 700;
    font-size: 10px;
    font-family: 'Open Sans Condensed';
    text-align: center;
    width: 150px;
    z-index: 3;
    background-color: $green-neutral;
    color: $white;
}

.id__sq_small {
    width: 48px;
    font-size: 48px;

    .ribbon {
        top: 7px;
        right: -65px;
        font-size: 4px;
        line-height: 1.8em
    }
}

.id__sq_medium {
    width: 72px;
    font-size: 72px;

    @include media-breakpoint-down(xm) {
        width: 55px;
        font-size: 55px;
    }

    .ribbon {
        top: 10px;
        right: -60px;
        font-size: 7px;
        line-height: 1.8em
    }
}

.id__sq_large {
    width: 120px;
    font-size: 120px;
}
