.container-box {
    border: 0;
    background-color: $gray-t6;
    border-radius: 24px;
    margin-bottom: 24px;

    &--spaced {
        margin-top: 24px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.container_header {
    text-align: justify;
    font-size: 0;
    line-height: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: $gray-t6;
    padding: 24px 24px 18px 24px;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    border-radius:24px;

    &:first-child {
        border-top: none;
    }

    &:after {
        display: inline-block;
        width: 100%;
        content: '';
    }
}

.container_header__actionable {
    // background: linear-gradient(rgba(45,45,45,0),rgba(45,45,45,.05)),#f5f5f5;
    // box-shadow: 0 2px 2px -1px rgba(45,45,45,.1), inset 0 1px 0 0 rgba(255,255,255,.95);
    h4 {
        font-size: 18px;
        text-transform:uppercase;
    }
}

.container_header--title {
    font-weight: 700;

    text-transform: uppercase;
    color: $black;

    &:last-child:only-child {
        margin-bottom: 0;
    }
}

.container_header--item {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    line-height: 1.25;
    margin: 0;
}

.container_content {
    padding: 24px;

    &__nav {
        cursor: pointer;
        border-bottom: 1px solid #cbcbcb;
        background-color: #f9f9f9;

        &:first-child {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        &:last-child {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-bottom: none;
        }

        &-wrapper {
            border: 1px solid #cbcbcb;
            border-radius: 3px;
        }

        &.selected {
            background-color: #fff;
            position: relative;

            &:before {
                position: absolute;
                transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                top: 50%;
                margin-top: -.5em;
                right: -.6em;
                content: '';
                display: block;
                width: 1em;
                height: 1em;
                background-color: #fff;
                border-top: 1px solid #cbcbcb;
                border-right: 1px solid #cbcbcb;
            }
        }
    }

    &--box {
        &:after {
            clear: both;
            content: "";
            display: block;
        }
    }

    &--toggled {
        display: none;

        &.selected {
            display: block;
        }

        @include media-breakpoint-down(xs) {
            &.toggled__section,
            &.feature-content {
                display: block;
                margin-bottom: 14px;
                padding-top: 14px;
                padding-bottom: 14px;
            }

            &.feature-content {
                border-bottom: 1px solid #cbcbcb;
            }
        }

        & .toggled__title {
            border-bottom: 1px solid #cbcbcb;
            padding-bottom: 14px;
            margin-bottom: 24px;
        }

        & .toggled__content {
            margin-bottom: 24px;
        }
    }

    dt {
        color: $text-color;
    }

    dd {
        div {
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            word-break: break-all;  
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }

    dl {
        margin: 0 0 24px;
    }
}

.container__placeholder {
    border-radius: 3px;
    border: 1px dashed #cbcbcb;
    padding: 24px;
    text-align: center;
    font-size: 16px;
    line-height: 1.6em;
    color: #646464;
    white-space: normal;
}

.stats {
    margin: 0 0 14px 0;
}

.container_footer {
    text-align: right;
    border-radius: 0 0 24px 24px;
    display: table;
    width: 100%;
    background-color: $gray-t6;

    padding: 10px;
}

.container_footer--item {
    text-align: left;
}

.container_footer--item, .container_footer--item__last {
    display: table-cell;
    vertical-align: middle;
}

.container-slider {
    display: none;

    &.show {
        display: block;
    }

    @include media-breakpoint-up(lg) {
        [data-truncate=line] {
            max-width: 180px;
        }
    }
}

.container-section--title {
    margin-bottom: 24px;
}

.container-toggle {
    &--trigger {
        display: block;
        margin-top: 6px;
    }

    &--less {
        display: block;
    }

    &--more {
        display: none;
    }

    &.toggled {
        .container-toggle--less {
            display: none;
        }

        .container-toggle--more {
            display: block;
        }
    }
}
