.tertiary-nav--items {
    margin-top: 0;
    margin-bottom: -8px;
    padding-left: 0;
    font-size: 0;
    color: $text-light;
    display: flex;
    justify-content: space-between;
}

.tertiary-nav--item {
    list-style: none;
    display: inline-block;

    .card__caption {
        font-size: 11px;
        color: $text-light;
        text-align: right;
        margin-top: -6px;
        margin-bottom: 6px;
        line-height: 14px;

        a {
            color: $text-light;
        }
    }

    .button__profile {
        margin-left: 6px;
        margin-top: 6px;
    }

    &.is-selected .tertiary-nav--link {
        color: $text-color;
        text-decoration: none;
        font-weight: bold;
    }
}

.tertiary-nav--link {
    color: $secondary-text-color;
    background-color: transparent;
    transition-property: color;
    transition-duration: .1s;
    transition-timing-function: linear;
    position: relative;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        text-decoration: none;
        color: $text-color;
        background-color: #e2e2e2;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding: 16px 20px;
    }
}

.tertiary-nav--content, .tertiary-nav--link {
    display: block;
    font-size: 18px;
    padding: 16px 20px;
}

.tertiary-nav__menu {
    padding: 12px 0;

    &__label {
        font-size: 14px;
    }
}

.tertiary_nav--item__right {
    margin-right: 0;
}

.tertiary-nav--button {
    text-align: right;
    padding: 12px 0;
    margin-top: -6px;
}

.tertiary-nav--link:before {
    position: absolute;
    width: 100%;
    content: '';
    bottom: 0;
    display: block;
    left: 0;
    transition-property: height;
    transition-duration: .1s;
    transition-timing-function: ease-in;
}

.tertiary-nav--item.is-selected .tertiary-nav--link:before {
    height: 8px;
    background-color: $green;
    border-radius: 8px;
}
