.rating {
    margin: 0;
    color: #cbcbcb;

    &:last-child {
        margin-bottom: 0;
    }

    &:first-child {
        margin-top: 0;
    }

    &__icon {
        margin-right: 2px;

        &:before {
            font-size: 14px;
            font-style: normal;
            font-family: SageUIIcons;
            line-height: 1em;
            content: "#{$sg-icon-favourite}";
        }

        &--positive {
            color: #00A159;
        }

        &--unrated {
            color: #e5e5e5;
        }
    }
}

.rating-input {
    $rate-color: $green-neutral;
    $hover-color: darken($rate-color, 10%);
    display: inline-block;
    &--element {
        display: none;

        &:checked {
            + label {
                &:hover {
                    color: $hover-color;
                }
            }
            ~ label {
                color: $rate-color;
                &:hover {
                    color: $hover-color;
                    ~ label {
                        color: $hover-color;
                    }
                }
            }
        }
    }
    &--label {
        color: #eaeaea;
        float: right;
        cursor: pointer;

        &:hover ~ input.rating-input--element:checked ~ label{
            color: $hover-color; /* current selection */
        }
    }
    &:not(:checked) {
         label {
            &:hover {
                color: $rate-color; /* current icon */
                ~ label {
                    color: $rate-color; /* previous icons */
                }
            }
        }
    }
}

.rating-details {
    display: inline-block;
    vertical-align: top;
    margin-left: 24px;
}
