.listing body {
	background-color: $white;
}

.listing-page {
    * {
        box-sizing: border-box;
    }

    .selector--input input {
        vertical-align: baseline;
    }

    &.listing-grid-list {
        .js-grid-view {
            @extend .button.is-active;
        }

        .listing-items-row {
            display: none;
        }

        .listing-items-grid {
            display: block;
        }
    }

    &.listing-show-list {
        .js-list-view {
            @extend .button.is-active;
        }

        .listing-items-row {
            display: block;
        }

        .listing-items-grid {
            display: none;
        }
    }

    .listing-row {
        display: -ms-flexbox;
        display: flex;

        &:not(:first-child) {
            border-top: 1px solid #eaeaea;
        }
    }

    .listing-row-content, .listing-row-image, .listing-row-toolbar {
        padding: 14px;
    }

    .listing-row-image {
        width: 148px;
        text-align: center;

        p {
            margin-bottom: 10px;
        }
    }

    .listing-row-content {
        word-wrap: break-word;
        padding-left: 0;
        padding-right: 0;
        -ms-flex: 1 0 1px;
        flex: 1 0 1px;
        min-width: 0;
    }

    .listing-row-badges {
        margin: 0 4px;
    }

    .listing-row-toolbar {
    }

    .listing-row-content-title {
        font: bold 18px/1.4em "Helvetica Neue",Helvetica,Arial,sans-serif;
        word-break: break-all;
        vertical-align: middle;
    }

    .listing-row-price {
        margin-bottom: 16px;
        font-size: 12px;

        .caption {
            margin-bottom: 2px;
        }
    }

    .listing-description {
        max-height: 90px;
        overflow: hidden;
    }

    .listing-row-actions {
        margin: 0 auto;
    }

    .price--value {
        font: bold 18px/1.25 'Sage Text', "Helvetica Neue",Helvetica,Arial,sans-serif;
    }

    .listing-items {
        position: relative;

        .container-box {
            background-color: transparent;
        }
    }

    .listing-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: #fff;
        opacity: .5;
    }


    .merchandising-badges {
        display: flex;
        align-items: center;
        margin-top: 6px;

        &:last-child {
            margin-right: -3px;
        }

        &:first-child {
            margin-left: -3px;
        }
    }

    .merchandising-badges-item {
        margin: 0 3px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .filters--tags {
        text-align: left;

        .tag {
            padding: 8px 34px 8px 0;
            margin-right: 14px;
        }
    }

    .tag {
        display: inline-block;
        border-radius: 16px;
        background-clip: padding-box;
        font-size: 16px;
        line-height: 1;
        padding: 8px 12px;
        width: auto;
        color: inherit;
        font-weight: 500;
        white-space: nowrap;
        margin: 16px 8px 0 0;
        position: relative;
    }

    .tag--text {
        display: inline-block;
        vertical-align: text-top;
    }

    .tag--remove {
        position: absolute;
        font-size: 12px;
        transition: color .1s linear;
        color: $white;
        right: 0;
        top: 0;
        padding: 8px;
        border-radius: 16px;
        text-decoration: none;
        background-color: $red;

        &:hover {
            text-decoration: none;
            background-color: $black;
        }

        &:before {
            font-style: normal;
            font-family: AppDirectIcons;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            content: "\e020";
        }
    }

    .listing-items-row, .listing-items-grid {
        display: none;
    }


    .tile-listing {
        font-size: 12px;
        line-height: 1.6em;
        position: relative;
        height: 240px;
        margin: 1%;
        //border: 1px solid lightgray !important;
        text-align: left;
        cursor: pointer;
        width: 31.33333%;
        border-radius: 24px;
        box-shadow: 2px 2px 12px rgba(0, 41, 64, 0.12);

        &:not(.tile--empty) {
            background-color: #fff;
        }

        &:nth-child(3n+1) {
            border-left-style: hidden;
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-top-style: hidden;
        }

        &:hover {
            background-color: #f5f5f5;
        }

        &.tile--empty {
            &:hover {
                background-color: transparent;
                cursor: auto;
            }

            border: none !important;
            box-shadow: none !important;
        }

        &.tile:nth-child(4n - 2) {
            padding-left: 14px;
        }

        .card__image {
            margin-right: 16px;
        }

        .id__sq_small {
            width: 64px;
            border: none;
            border-radius: 16px;
        }
    }

    .tile--details {
        text-align: justify;
        font-size: 0;
        line-height: 0;
        text-justify: distribute-all-lines;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 14px;
        width: 100%;
    }

    .summary--title {
        margin-bottom: 0;
    }

    .tile--details_item {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
    }

    .tile__content--description {
        word-wrap: break-word;
        height: 76px;
        overflow: hidden;
        margin-bottom: 6px;
    }

    .card {
        margin: 14px 0;
        text-align: left;
        overflow: hidden;

        &:first-child {
            margin-top: 0;
        }
    }

    .listing-grid-price {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .listing-grid-price-container {
        -ms-flex: 1 0 1px;
        flex: 1 0 1px;

        .subtitle {
            margin-bottom: 0px;
        }
    }

    .listing-grid-price-compare {
        margin-left: 10px;
    }

    .selector {
        cursor: pointer;
    }

    .selector__small {
        line-height: 1.6em;
        font-size: 12px;
    }

    .ribbon-wrapper {
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        height: 65px;
        width: 65px;
    }

    .button__filter-show, .button__filter-hide {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        .listing-toggle-grid {
            display: none !important;
        }
    }

    @include media-breakpoint-down(md) {
        .listing {
            &-row-image {
                width: 90px;

                .id--placeholder {
                    font-size: .35em;
                }
            }

            .ribbon {
                line-height: 16px;
                right: -58px;
                font-size: 7px;
                top: 10px;
            }

            .tile-listing {
                .rating {
                    margin-top: 0px;
                }
            }

            .rating {
                margin-top: -11px;

                .rating__icon:before {
                    font-size: 11px;
                }
            }

            .toolbar {
                margin-top: -11px;

                & .toolbar--item ~ .toolbar--item {
                    @include media-breakpoint-down(md) {
                        margin-top: 14px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .listing-navigator {
            display: block !important;
        }
    }

    @include media-breakpoint-down(xs) {
        .listing {
            &-page {
                position: relative;
            }

            &-navigator {
                display: none;
            }

            &-filters {
                .adb-selector {
                    padding: 3px 0;
                }
            }
        }

        .filters--filter {
            vertical-align: top;
        }

        .compare-label {
            .tooltip__text {
                display: none;
            }
        }

        .subtitle {
            display: none;
        }

        .pagination {
            margin-top: 5px;
            text-align: center;

            .pagination--button {
                padding: 6px 10px;
            }
        }

        .button__filter-show, .button__filter-hide {
            display: inline-block;
        }

        .button__filter-clear {
            display: none;
        }
    }

    @include media-breakpoint-down(xs) {
        .listing {
            &-row {
                flex-wrap: wrap;

                .button__primary {
                    display: none;
                }

                &-content {
                    width: calc(100% - 100px);
                    flex: none;
                }

                &-toolbar {
                    width: 100%;
                    text-align: left;
                    flex-direction: unset;
                    padding-top: 0;
                    margin-left: 90px;
                }

                &-price {
                    margin-top: 6px;
                    margin-bottom: 6px;
                }
            }
        }

        .js-listing-item-price, .price--value, .price--unit {
            font-size: 12px;
            display: inline;
        }

        .price--value {
            font-size: 18px;
            margin-right: 0;
        }
    }

    @include media-breakpoint-down(xm) {
        h3 {
            font-size: 16px;
            line-height: 1.6;
        }

        .pagination {
            .pagination--button {
                padding: 6px 7px;
            }
        }
    }
}
.icon__list,
.icon__grid {
    font-size:16px;
}
button[data-auto='clear-filters'].button__inset {
    border: none;
    padding-right: 35px;
    font-size:14px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAHKADAAQAAAABAAAAHQAAAABZ3d0SAAAEQ0lEQVRIDa2Xf2wTZRjHn16vv3+s12ZDQbrRudU/JgrDKTCjm9nMCIHiiJqOKCYQxvwHJAqJS/zBiMsSjZCIP6KGhGT6h0vwH/8hUclkqTNtcQ5kQmDp1m1utld6a7v2rj3vbXfjrtef1vuj7/O8z/N8P/e+73PXVgZFrnA43JiYnnVEr7g64u4/rHQwuD75T0gPbAowo35ZXV83p2pq8Gm3t1zG662XjEbjX4UkZfmCoVCoOe65PhT6+pv2+I3b+dJE88p6K5gOO39Utzz+lslkcouCq44EyLIsTt6+83Fw6NO+qHtSEs8lkj2n3fYoa36z9zzxsO2YTCZjhHGRILcqgp6cGll858O2JBkW5pVtywkj1Lx34idFk72bWy3JC2C8gVaWcE+OLLxxumIY0kQ3jLQS166PIG2eswZE27h05lwby4h2gM/7TyPSWnr/bBvS5gXSQNQg5Edf9CVDlW0jLyockSbSRgw0nwaujHmGIr/+LjpPYVGlNtJeGZ8YSgO558xOfnaxvZiopuVJkAMrTWP1ULXjEel81gx5/kI7eqaxxJ+39tKz81lhsavZ0wu2T94F28BrYigHW3dmAKxnB+GhvY3ioiyP9s1B4ta0A4uOX+vIiondqkbYcHw3YNzmq7teug9dhdV02bmDUQFx7Dhoq3LsgEAtOvZbh7y3urY/uRi0CObFZjwAyy4fGDp3Aq7CAG9oAoNVA8rnXoY0DGVT8zBz9G2g/FFxbZbHMjQju9HVQzF/B/RZMYmrtLdC3eenQGWQi2MI1nsSQjeXxPM5PPzB6mUsGQwXhaHaxNQvMH3kHDC0QImmwF8iDFUlyXt6jE0lBQoFTO7MzAd3Aa4Q5CgMYHl1j7iRBOFsk6UZwLh33nJ2QOJzsAcGB6C6k2sQdFFLkKAyN6ru3A+2Dw6VBMXNVRSGm81zGZU8nxIYOrMTcPfIIMTLhMoJYh5TNtT686Ay0xYzqDaty9iCBsmc6X2owmYFRf5eT9crG+r8mPqJx0YLAoM+8PWdhLDnpqQbeWjMexXuHD0NK4HCb0ft9q2jMnJxccvMroMeSKUKcisOcm+OjT9c2IoRNTVew+62qYoFiwggBmKlvy2IV17sl6mE/V6kusywTMO9+jgGKksDTbba74gDL7jK1Ck5nejZ50KMNSAyLD37nPqO1kDJKiUmIk2L0+Hk09d+a2hMprsUSXanotHL0aue/2V/da3NdPWp17uRNg+U9DEHfYb86tuR0PD3RZ4qXiL3SBxwBEyHe7oNBsMVYYYEiIKxWGwT5Z4YDn05/NTKRHkNrN5sB9Mhp8vQvNmp0WjWVsZDcwL5IEVR+6Mu70Dk5zF7bNwLycA9PiQaZQocNDuaQff8s1P6ndv6uVWlG0SUtOoUBPIFkUhkC8MwDnpm/ml6dmFDcmFhPRun0X+LOaVtox+31Y4qtdpLOp3Oy9fkG/8FqK2sbr+21XkAAAAASUVORK5CYII=') no-repeat  right;
}