.filters {
    background-color: #f5f5f5;
    border-bottom: 1px solid #cbcbcb;
    padding: 10px;
}

.container_header--item__last {
    float: right;
}

.input_row {
    border-radius: 3px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    &.is-focused .input_row--item_content {
        border-color: #9c9c9c;
    }
}

.filters--header {
    text-align: justify;
    font-size: 0;
    line-height: 0;
    text-justify: distribute-all-lines;
    min-height: 28px;
}
.filters--header_item {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    line-height: 1em;
    margin: 0;
}

.input_row--item_content {
    border-radius: 0;
    display: block;
    margin-left: -1px;
}

.filters--header .button {
    float: left;
}

.search_field--input,
.text,
[type=date],
[type=email],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=url],
textarea {
    box-shadow: inset 0 1px 3px 0 rgba(45,45,45,.1);
    outline: none;
    font-size: 14px;
    line-height: 1.6em;
    padding: 7px 12px 5px;
    min-height: 36px;
    border-radius: 0;
    border: 1px solid $grey-border;
    background-color: #fff;
    color: $black;
    &:focus {
        box-shadow: inset 0 1px 3px 0 rgba(45,45,45,.2);
        border-color: #9c9c9c;
        background-color: #fff;
    }
    &::placeholder {
        color: $text-light;
    }
}
.text__small {
    font-size: 12px;
    box-sizing: border-box;
    line-height: 1.6em;
    padding: 5px 8px 3px;
    height: 28px;
}

.form--inline_field {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
    font-size: 14px;
}

input[type=search] {
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.form--field > label,
.label {
    margin: 4px 0;
    display: block;
}

.form--nested_fields {
    border-left: 0;
    padding: 0 16px;
}

.form--container_field,
.form--field {
    margin-bottom: 14px;
}

.selector--input {
    display: block;
    float: left;
    margin-right: 4px;
    width: 18px;
    & input {
        vertical-align: middle;
    }
}

.selector--value {
    font-weight: normal;
    display:block;
    margin-left: 22px;
}

.option-selector {
    opacity: 0.8;
    clear: left;
    cursor: pointer;
    .selector--value {
        
    }
    &:hover {
        opacity: 1;
        .selector--value {
            opacity: 1;
        }
    }
}

.filters--filter {
    display: inline-block;
    margin-right: 10px;
}

.label__inline {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    margin: 0 4px;
}

.is-disabled {
    opacity: .5;
    box-shadow: none;
    cursor: not-allowed;
    .selector--value {
        opacity: .5;
    }
}

.input_row {
    border-radius: 3px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;

    input {
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .form--field {
        label {
            margin: 4px 0;
            display: block;
            font-weight: 700;
            &.input_row--item_content {
                margin-left: -1px;
            }
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--label {
        .input_row--item_content {
            height: 36px;
            padding: 0 12px;
            border: 1px solid #cbcbcb;
            background-color: #f5f5f5;
            color: #646464;
            font-weight: 400;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-align: center;
            align-items: center;
        }
    }
}

form {
    ::placeholder {
        opacity: 0.6;
    }
    .dropdown {
        height: 36px;
        &:before  {
            padding: 6px 9px;
            font-size: 16px;
        }
    }
    
    :focus {
        outline: 0;
        box-shadow: inset 0 1px 3px 0 rgba(45,45,45,.2);
        border-color: #9c9c9c;
        background-color: #fff
    }
    &.form--compact {
        max-width: 40em;
    }
    input,
    select{
        width: 100%;
        max-width: 20em;
    }
    textarea {
        width: 100%;
        overflow: auto;
        vertical-align: top;
        resize: vertical;
        min-height: 6em;
        box-shadow: inset 0 1px 3px 0 rgba(45,45,45,.1);
        font-size: 14px;
        line-height: 1.6em;
        padding: 7px 12px 5px;
        height: 36px;
        border-radius: 3px;
        border: 1px solid #cbcbcb;
        background-color: #fff;
        color: #575757;

        &:focus + .js-maxlength-count {
            display: block;
        }

        .placeholder {
            color: #cbcbcb;
        }
    }
    .label--error, .label--status {
        margin-left: 6px;
        font-weight: 400;
        font-size: 12px;
        line-height: normal;
    }
    .label--status {
        color: #646464;
    }
    .js-maxlength-textarea {
        position: relative;

        textarea {
            max-width:40em;
            resize: none;
        }
    }
    .js-maxlength-count {
        border-radius: 3px;
        position: absolute;
        right: 10px;
        bottom: 0;
        display: none;
        padding: 6px 12px;
        z-index: 3;
        color:  #707070;
        font-size:  12px;

        &.is-error,
        &[data-error] {
            color: #e33d51;
        }
    }
    .is-error,
    [data-error] {
        input,
        select,
        textarea {
            border-color: #f0949f;
            background-color: #fef9fa;
            box-shadow: inset 0 1px 3px 0 rgba(227,61,81,.1);
        }
    }
    .label--error {
        color: #cc3749;
        display: none;
    }
    .is-error {
        .label--error {
            display: inline-block;
        }
        .dropdown {
            border-color: #f0949f;
        }
    }
}
