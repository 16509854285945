.home {


    .btn-view-all {
        width: 100%;
        margin-bottom: 24px;
    }

    .featured-slider .clickable-layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .ad-universalnav .adsearch-universal {
        #adsearch {
            border: 0;
            border-bottom: 2px solid white;

            > div {
                border: none;
            }

            input {
                padding: 8px 0px;
            }

            .search_field {
                background-color: transparent;
                border: none;
                height: 48px;

                i::before {
                    font-family: SageUIIcons;
                    content: "#{$sg-icon-search}";
                    color: $white;
                    font-size: 24px;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .ad-universalnav .adsearch-universal {
            position: absolute;
            top: 220px;
            justify-content: center;
            padding: 0 15%;
        }

        .ad-universalnav .appdirectUniversalSearch {
            max-width: 776px;
        }

        .ad-universalnav #adsearch {
            max-width: none;
        }

        .ad-universalnav #adsearch > div {
            background-color: #000;
        }

        .ad-universalnav #adsearch input {
            font-family: 'Sage Text', Arial, 'Helvetica Neue', Roboto, Sans-Serif;
        }

        .appdirectUniversalSearch {
            width: 400px !important;
        }

        #adsearch > div, #adsearch > div > div {
            height: 40px !important;
        }
    }

    #adsearch input {
        font-size: 17px !important;
    }

    @media (min-width: 1020px) {
        .ad-universalnav .adsearch-universal {
            top: 180px;
        }
    }

    @media (min-width: 1900px) {
        .ad-universalnav .adsearch-universal {
            top: 280px;
        }

        .ad-universalnav #adsearch > div > div {
            height: 64px;
        }

        .ad-universalnav #adsearch input {
            font-size: 20px;
        }

        .ad-universalnav #adsearch .search_field i::before {
            font-size: 18px;
            vertical-align: middle;
        }

        .ad-universalnav #adsearch .search_field {
            height: 40px;
        }
    }

    .hero-content {
        max-width: 880px;
        margin: 0 auto;
        text-align: center;
    }

    .hero-banner .light {
        //font-weight: 300;
    }

    .hero-banner h1 {
        color: #fff;
        font-size: 56px;
    }

    .hero-banner .lead {
        font-size: 18px;
        line-height: 1.5;
    }

    .hero__item_content {
        p {
            font-size: 14px;
        }

        a {
            display: inline-block;
        }
    }

    @media (max-width: 767px) {
        .featured-slider {
            color: #fff;
        }

        .featured-slider .header--item {
            color: #fff;
        }

        .featured-slider .header--item .button {
            color: #fff;
        }

        .featured-slider .slider--item h3 a {
            color: #fff;
        }

        .featured-slider .slider--item .subtitle {
            color: rgba(255,255,255,.64);
        }

        .featured-slider .rating__icon--positive {
            color: #ffb500;
        }
    }

    @media (min-width: 768px) {
        .featured-slider .slick-track {
            padding-bottom: 2px;
            min-height: 306px;
        }

        .featured-slider .slider--item {
            padding: 24px;
            background-color: #fff;
            width: calc((100vw - 64px - 47px)/3);
            border-radius: 0;
            color: rgba(0,0,0,.9);
            box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.32);
            min-height: 304px;
            transition: background-color .3s ease-in-out;

            &:hover {
                background-color: rgba(255,255,255,.84);
            }
        }

        .featured-slider .header--item {
            color: #fff;
        }

        .featured-slider .header--item .button {
            color: #fff;
        }
    }

    @media (min-width: 1024px) {
        .featured-slider {
            padding: 0 92px;
            position: relative;
        }

        .featured-slider .slider--item {
            width: calc((100vw - 184px - 47px)/3);
            min-height: 208px;
        }

        .featured-slider .logo-container {
            width: 100px;
            height: 100px;
            float: left;
            margin: 0 16px 16px 0;
        }

        .featured-slider .slick-track {
            min-height: 210px;
        }

        .featured-slider .id__logo {
            width: 100px;
        }

        .featured-slider .header--item > .button {
            border: 0;
            padding: 6px 0;
            background-color: transparent;
        }

        .featured-slider .toggle_buttons {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 !important;
        }

        .featured-slider .js-slider-prev {
            background-color: #009900;
            position: absolute;
            left: 0;
            height: 64px;
            border: 0;
            line-height: 52px;
        }

        .featured-slider .js-slider-next {
            background-color: #009900;
            position: absolute;
            right: 0;
            height: 64px;
            border: 0;
            line-height: 52px;
        }

        .featured-slider .button:disabled {
            background-color: rgba(0,0,0,.24);
            border: 0;
        }

        .featured-slider .js-slider-prev i::before {
            content: "\e91e";
        }

        .featured-slider .js-slider-next i::before {
            content: "\e91f";
        }

        .featured-slider .slider--item_content--headline {
            clear: left;
            font-size: 14px;
            line-height: 1.5;
        }
    }

    @media (min-width: 1400px) {
        .featured-slider {
            padding: 0 148px;
        }

        .featured-slider .slider--item h3 a {
            font-size: 20px;
        }

        .featured-slider .slider--item {
            width: calc((100vw - 296px - 17px - 70.5px)/4);
        }

        .featured-slider .js-slider-prev {
            width: 48px;
        }

        .featured-slider .js-slider-next {
            width: 48px;
        }
    }

    @media (min-width: 1900px) {
        .featured-slider .logo-container {
            width: 120px;
            height: 120px;
            float: left;
            margin: 0 16px 16px 0;
        }

        .featured-slider .slider--item h3 a {
            font-size: 24px;
        }

        .featured-slider .slider--item {
            min-height: 252px;
        }

        .featured-slider .slick-track {
            min-height: 254px;
        }

        .featured-slider .id__logo {
            width: 120px;
        }

        .hero-banner {
            padding: 50px 0;
        }

        .hero-content {
            margin-bottom: 50px;
        }
    }

    @media (max-width: 1899px) {
        .hero-banner {
            padding: 50px 0;
        }

        .hero-content {
            max-width: 80%;
            margin-bottom: 50px;
        }

        .hero-banner h1 {
            font-size: 48px;
        }

        .hero-banner .lead {
            font-size: 16px;
        }
    }

    @media (max-width: 1440px) {
        .hero-banner {
            padding: 40px 0;
        }

        .hero-content {
            margin-bottom: 45px;
        }
    }

    @media (max-width: 1023px) {
        .hero-banner {
            padding: 40px 0;
        }

        .hero-content {
            max-width: 68%;
            margin-bottom: 45px;
        }

        .hero-banner h1 {
            font-size: 42px;
        }
    }

    @media (max-width: 767px) {
        .hero-banner {
            padding: 30px 0;
        }

        .hero-content {
            max-width: 100%;
            padding: 0;
            text-align: center;
            margin-bottom: 10px;
        }

        .hero-banner h1 {
            font-size: 32px;
        }
    }
}
