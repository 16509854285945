.badges {
    display: block;
    font-size: 0;
    line-height: 0;
    margin-top: 4px;
}

.badge {
    color: #fff;
    background-color: #fff;
    border-radius: 14px;
    display: inline-block;
    font-size: 12px;
    line-height: 1em;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    white-space: nowrap;
    padding: 8px 12px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:hover {
        background-color: white;
    }

    a {
        text-decoration: none;
        display: block;
        font-weight:500;
    }

    &__outline {
        color: $text-color;
        background-color: transparent;
        border: 2px solid $black;

        a {
            color: $black;

            &:hover {
                color: $black;
                text-decoration:none;
            }
        }
    }

    &--emphasis {
        background-color: $green-neutral;
    }

    &--success {
        background-color: $green-neutral;
    }

    &--pending {
        background-color: $gold;
        color: $black;
    }
}

.is-fixed {
    .badge {
        display: none;
    }
}
