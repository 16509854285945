.domains {
    &__search {
        display: flex;
    }
    &__result {
        margin-top: 24px;
    }
    &__column {
        min-width: 300px;
    }
    &__search-box {
        -ms-flex: 1 0 1px;
        flex: 1 0 1px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
    }
    &__search-button {
        height: 36px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &__checkout {
        text-align: right;
    }
    &__row {
        align-items: center;
        border-bottom: 1px solid #eaeaea;
        padding: 6px 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
    &__name {
        min-width: 180px;
        & strong {
            padding-right: 12px;
        }
    }
    &__price {
        white-space: nowrap;
    }
    &__action {
        text-align: right;
    }
    @include media-breakpoint-down(xs) {
        &__button span {
            display: none;
        }
    }
}
