.profile-checklist {
    padding-left: 32px;

    &--flush {
        padding-left: 0;
    }
    &__item {
        position: relative;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            font-family: SageUIIcons;
            text-align: center;
            display: inline-block;
            width: 16px;
            content: " ";
            position: absolute;
            left: -26px;
            font-size: 16px;
        }
        &--checked:before {
            color: #009900;
            content: $sg-icon-check;
        }
    }
}

.profile-edition {
    display: flex;
    flex-direction: column;
    border: 1px solid #cbcbcb;
    background-color: #fff;
    border-radius: 0;

    &__header {
        background-color: #f5f5f5;
        padding: 16px;

        & h3 {
            text-align: center;
            margin-bottom: 0;
        }
    }
    &__content {
        padding: 14px;
    }
    &__prices {
        margin-bottom: 14px;
        &-separator {
            margin-left: 0;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            z-index: 1;
            & span {
                font-size: 10px;
                display: inline-block;
                padding: 0 8px;
                background-color: #fff;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #eaeaea;
            }
        }
    }
    &__price {
        & .edition__price-amount {
            font: bold 14px/1.25 'Sage Text', "Helvetica Neue", Roboto, Arial, sans-serif;
            font-weight: 400;
        }
        &:first-child .edition__price--primary-amount {
            font: bold 24px/1.25 'Sage Text', "Helvetica Neue", Roboto, Arial, sans-serif;
        }
        & .edition__price-frequency, & .edition__price-unit {
            text-transform: lowercase;
        }
    }
    &__price--primary {
        margin-top: 0;
        margin-left: 0;
        & .edition__price--primary-amount {
            font-size: 18px;
            font-weight: bold;
        }
        &:before {
            font-family: AppDirectIcons;
            text-align: center;
            display: inline-block;
            width: 24px;
            content: " ";
            position: absolute;
            left: -24px;
        }
    }
    &__action {
        text-align: center;
        margin-bottom: 14px;
    }
    &__details {
        border-bottom: 1px solid $grey-border;
        padding-bottom: 12px;
        margin-bottom: 12px;
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
    &__bullets {
        li:nth-child(n+8) {
            display: none;
        }
        .toggle-show,
        .toggle-hide {
            display: inline-block;
        }
        .toggle-hide {
            display: none;
            margin-top: 6px;
        }
        &.toggled {
            li:nth-child(n+8) {
                display: list-item;
            }
            .toggle-show {
                display: none;
            }
            .toggle-hide {
                display: inline-block;
            }
        }
    }

    .profile-checklist__item.profile-edition__price {
        margin-left: 25px;
        margin-top: 10px;
        &:before {
            font-family: AppDirectIcons;
            content: "\e631";
            font-size: 10px;
            position: absolute;
            left: -25px;
            text-align: center;
            width: 19px;
        }
        &--primary, :first-child {
            margin-left: 0;
            margin-top: 0;
            &:before {
                content: " ";
            }
        }
        &--previous {
            text-decoration: line-through;
        }
    }
}

.profile-box {
    margin-bottom: 24px;
    @include media-breakpoint-down(xs) {
        max-width: 345px;
    }
}

.profile-post--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    & .caption {
        white-space: normal;
    }
}

.reviews-summary {
    flex: 1 0 1px;
    display: flex;
    min-width: 65%;
    padding: 7px 0;
    &--average-rating, &--user-rating, &--no-rating {
        flex: 1 0 1px;
    }
    &--no-rating {
        padding: 7px 0;
        min-width: 60%;
        margin-bottom: 0;
    }
}

@include media-breakpoint-down(xs) {
    &.profile__image {
        margin-top: 14px;
    }
}

@include media-breakpoint-down(xm) {
    &.profile-content {
        padding-left: 8px;
        padding-right: 8px;
    }
    &.title__xxxxxlarge {
        font-size: 22px;
    }
    &.feature-actions {
        text-align: center;
    }
    &.featured-reviews {
        .slat--image_caption {
            font-size: 11px;
        }
    }
}

.profile-resources {
    &__item iframe {
        min-height: 340px;
        @include media-breakpoint-down(xm) {
            min-height: 200px;
        }
    }
}

.profile-entries {
    .slat {
        &--content {
            .subtitle {
                white-space: normal;
            }
            @include media-breakpoint-down(xm) {
                .badge {
                    white-space: normal;
                }
                .caption {
                    font-size: 11px;
                }
            }
        }
    }
}

.profile-content {

    .container-toggle {
        margin-bottom: 8px;
    }
}
