.stack {
    //border: 1px solid $grey-border;
    background-color: #fff;
    border-radius: 0;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;

    &__noborder {
        border: none;
    }
}

.stack--item_content {
    position: relative;
    padding: 12px 30px 12px 10px;
    &:hover {
        text-decoration: none;
    }
}

.link__nav {
    overflow: hidden;
    color: $black;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    line-height: 1.5;
    border-radius: 24px;
    text-decoration: none;

    &:active,
    &:focus,
    &:hover {
        background-color: $gray-t6;
        color: inherit;
    }

    &.selected {
        color: $black;
        font-weight: 700;
        border-radius: 0;

        &:active,
        &:focus,
        &:hover {
            background-color: $white;
        }

        &::before {
            border-left: 7px solid #00D639;
            border-radius: 4px;
            content: "";
            position: absolute;
            left: 0;
            right: auto;
            top: 0;
            bottom: 0;
        }
    }
}

.link__nav--icon {
    font-size: 16px;
    margin-top: -1px;
    display: inline-block;
    font-style: normal;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    font-family: AppDirectIcons;
    margin-right: 6px;
    vertical-align: top;
    min-width: 16px; /* preventing the text from moving while the image loads */
}

.stack--affix {
    position: absolute;
    right: 10px;
}

.stack-count {
    color: #707070;
    font-size: 12px;
}
