$border-color: $grey-border;
$bg-color: $gray-t6;
$bg-color-header: $gray-t6;

.list-container {
    background-color: $bg-color;
    margin-bottom: 24px;
    border-radius:24px;
    padding-bottom:16px;
    &:last-child {
        margin-bottom: 0;
    }
}

.list-multiple-navigation {
    .list-container_header {
        border-top: 1px solid transparent;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        .button__filter {
            display: none;
        }

        @include media-breakpoint-down(xs) {
            .button__filter {
                display: inline-block;
            }
        }
    }

    &:first-child .list-container_header {
        border-top: 1px solid transparent;
    }
}

.list-container_header {
    background-color: $bg-color-header;
    padding: 40px 24px 24px 24px;
    text-align: justify;
    font-size: 0;

   
}

.list-container_header--title {
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: $black;

    &:last-child:only-child {
        margin-bottom: 0;
    }
}

.list-container_header--item {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    line-height: 1;
    margin: 0;
}

.list-stack {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    .list-stack {
        padding-bottom:24px;
    }
}

.list-stack--item {
    list-style: none;
}

ul.list-stack--nested {
    .list-stack--item_content {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.list-stack--item_content {
    position: relative;
    padding: 8px 24px;
}

.list-link__nav {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $text-color;
    display: block;
    line-height: 1.5;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;

    &.selected {
        color: $text-color;
        background-color:$green;

        &:active,
        &:focus,
        &:hover {
           
        }

        .list-count {
            color: $text-color;
        }
        
    }
}

.list-link__nav:active,
.list-link__nav:focus,
.list-link__nav:hover {
    text-decoration: none;
}

.list-stack--affix {
    position: absolute;
    right: 10px;
}
.list-count {
    color: $text-color;
    font-size: 12px;
}

.list-stack--item__nesting {
    .list-stack:last-child {
        border-radius: 0 0 24px 24px;
        border-bottom: none;
    }
    .list-stack {
       

       
       
    }
    [data-toggle=collapse] {
        position: relative;
        padding-left: 34px;

        &:before {
            font-family: SageUIIcons;
            content: $sg-icon-caret-right;
            font-size: 16px;
            position: absolute;
            left: 12px;
            top: 10px;
            color: $grey-icon;
        }
        +.list-stack {
            display: none;
        }
        &.open {
            &:before {
                font-family: SageUIIcons;
                content: $sg-icon-dropdown;
            }
            +.list-stack {
                display: block;
            }
        }
    }
}
