//// 
/// Base Theme: Acme Variation: Spacing Metrics
//// 

/// Padding
$padding-vertical:
    xxsmall 0px,
    xsmall 2px,
    small 4px,
    medium 6px,
    large 8px,
    xlarge 10px,
    xxlarge 12px !default;
$padding-horizontal:
    xxsmall 2px,
    xsmall 4px,
    small 8px,
    medium 12px,
    large 16px,
    xlarge 20px,
    xxlarge 24px !default;

/// Margin
$margin:
    xxsmall 4px,
    xsmall 6px,
    small 10px,
    medium 14px,
    large 18px,
    xlarge 22px,
    xxlarge 24px !default;
