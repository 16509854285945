@import 'button-colors';

.toolbar {
    & .toolbar--item {
        display: inline-block;
        vertical-align: middle;
        border-radius: 24px;
    }

    & .toolbar--item ~ .toolbar--item {
        margin-left: 10px;
    }

    &__small .toolbar--item ~ .toolbar--item {
        margin-left: 10px;
    }

 /*   & .toolbar--item.button {
        border: 2px solid black;
        background-color: white;
        font-weight: 500;
        text-decoration: none;

        &:hover {
            background-color: $black;
            color: $white;
        }
    }*/
}

.button {
    display: inline-block;
    font-family: "Sage Text", Arial, "Helvetica Neue", Helvetica, sans-serif;
    text-transform: none;
    font-size: 16px;
    line-height: 1.6em;
    padding: 8px 12px;
    width: auto;
    font-weight: 500;
    color: $BUTTON_COLOR;

    &.button__square {
        padding: 8px 0;
        width: 40px;
    }

    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    box-sizing: border-box;
    border-radius: 24px;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;

    &:disabled, &.is-disabled {
        opacity: 0.5;
    }

    &:disabled:hover, &.is-disabled:hover {
        cursor: not-allowed;
        background-color: $BUTTON_DISABLED_BACKGROUND;
    }

    &:disabled:active, &.is-disabled:active,
    &:disabled:focus, &.is-disabled:focus,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        text-shadow: none;
        color: $BUTTON_DISABLED_COLOR;
        background-color: $BUTTON_DISABLED_BACKGROUND;
    }


    &:disabled, &.is-disabled {
        color: $BUTTON_DISABLED_COLOR;
        background-color: $BUTTON_DISABLED_BACKGROUND;
    }

    &:hover,
    &:focus {
        color: $BUTTON_COLOR_SELECTED;
        border-color: $BUTTON_BORDER_HOVER;
        background-color: $BUTTON_BACKGROUND_HOVER;
    }

    &:active,
    &.is-active {
        color: $BUTTON_COLOR_SELECTED;
        border-color: $BUTTON_BORDER_SELECTED;
        background-color: $BUTTON_BACKGROUND_SELECTED;
        //box-shadow: inset 0 2px 6px -1px $BUTTON_BOX_SHADOW_SELECTED;
    }

    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_DISABLED_COLOR;
    }




    &__error {
        &,
        &:disabled, &.is-disabled {
            border-color: $red;
        }

        &:hover,
        &:focus {
            border-color: $red;
        }

        &:active,
        &.is-active {
            border-color: $red;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            border-color: $red;
            box-shadow: none;
        }
    }

    &__inset {
        & {
            color: $black;
            border: 1px solid #c4c4c4;
            background: transparent;
        }

        &:disabled, &.is-disabled {
            color: $BUTTON_INSET_COLOR;
            border: 1px solid $BUTTON_INSET_BORDER;
            background: $BUTTON_INSET_BACKGROUND;
        }

        &:hover,
        &:focus {
            color: $black;
            border-color: $BUTTON_INSET_BORDER_HOVER;
            background: $white;
        }

        &:active,
        &.is-active {
            color: $BUTTON_INSET_COLOR_SELECTED;
            border-color: $BUTTON_INSET_BORDER_SELECTED;
            background: $BUTTON_INSET_BACKGROUND_SELECTED;
            box-shadow: inset 0 1px 3px 0 $BUTTON_INSET_BOX_SHADOW_SELECTED, 0 1px 0 0 $BUTTON_INSET_BOX_SHADOW_SELECTED_2;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover {
            color: $BUTTON_INSET_COLOR;
            border-color: $BUTTON_INSET_BORDER;
            background-color: $BUTTON_INSET_BACKGROUND;
            box-shadow: none;
        }
    }

    &__inset-dark {
        &,
        &:disabled, &.is-disabled {
            color: $BUTTON_INSET_DARK_COLOR;
            border: 1px solid $BUTTON_INSET_DARK_BORDER;
            background: $BUTTON_INSET_DARK_BACKGROUND;
        }

        &:hover,
        &:focus {
            color: $BUTTON_INSET_DARK_COLOR_HOVER;
            border-color: $BUTTON_INSET_DARK_BORDER_HOVER;
            background: $BUTTON_INSET_DARK_BACKGROUND_HOVER;
        }

        &:active,
        &.is-active {
            color: $BUTTON_INSET_DARK_COLOR_SELECTED;
            border-color: $BUTTON_INSET_DARK_BORDER_SELECTED;
            background: $BUTTON_INSET_DARK_BACKGROUND_SELECTED;
            box-shadow: inset 0 1px 3px 0 $BUTTON_INSET_DARK_BOX_SHADOW_SELECTED, 0 1px 0 0 $BUTTON_INSET_DARK_BOX_SHADOW_SELECTED_2;
        }

        &:disabled:hover, &.is-disabled:hover,
        &:disabled:hover:hover, &.is-disabled:hover:hover {
            color: $BUTTON_INSET_DARK_COLOR;
            border-color: $BUTTON_INSET_DARK_BORDER;
            background-color: $BUTTON_INSET_DARK_BACKGROUND;
            box-shadow: none;
        }
    }

    &__small {
        font-size: 12px;
        height: 33px;
        padding: 5px 8px;

        &.button__square {
            padding: 6px 0;
            width: 33px;
        }
    }

    &__large {
        font-size: 16px;
        height: 47px;
        padding: 8px 12px;

        &.button__square {
            padding: 10px 0;
            width: 47px;
        }
    }

    &__xlarge {
        font-size: 18px;
        height: 56px;
        padding: 13px 20px;

        &.button__square {
            padding: 13px 0;
            width: 56px;
        }
    }

    &__full_width {
        display: block;
    }
}

.button__emphasis {
    color: $BUTTON_EMPHASIS_COLOR;
    border: 2px solid $BUTTON_EMPHASIS_BORDER;
    background: $BUTTON_EMPHASIS_BACKGROUND;


    &:hover,
    &:focus {
        color: $BUTTON_EMPHASIS_COLOR;
        border-color: $BUTTON_EMPHASIS_BORDER_HOVER;
        background-color: $BUTTON_EMPHASIS_BACKGROUND_HOVER;
    }

    &:active,
    &.is-active {
        color: $BUTTON_EMPHASIS_COLOR_SELECTED;
        border-color: $BUTTON_EMPHASIS_BORDER_SELECTED;
        background-color: $BUTTON_EMPHASIS_BACKGROUND_SELECTED;
    }

    &:disabled, &.is-disabled,
    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_DISABLED_COLOR;
        border: 2px solid $BUTTON_DISABLED_BORDER;
        background: $BUTTON_DISABLED_BACKGROUND;
    }
}

.button__danger {
    color: $BUTTON_DANGER_COLOR;
    border-color: $BUTTON_DANGER_BORDER;
    background-color: $BUTTON_DANGER_BACKGROUND;

    &:hover,
    &:focus {
        color: $BUTTON_DANGER_COLOR;
        border-color: $BUTTON_DANGER_BORDER_HOVER;
        background-color: $BUTTON_DANGER_BACKGROUND_HOVER;
    }

    &:active,
    &.is-active {
        color: $BUTTON_DANGER_COLOR_SELECTED;
        border-color: $BUTTON_DANGER_BORDER_SELECTED;
        background-color: $BUTTON_DANGER_BACKGROUND_SELECTED;
        ;
    }

    &:disabled, &.is-disabled {
        color: $BUTTON_DANGER_COLOR;
        border: 1px solid $BUTTON_DANGER_BORDER;
        background-color: $BUTTON_DANGER_BACKGROUND;
    }

    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_DISABLED_COLOR;
        border-color: $BUTTON_DISABLED_BORDER;
        background-color: $BUTTON_DISABLED_BACKGROUND;
    }
}

.button__secret {
    text-decoration: underline;

    &,
    &:disabled, &.is-disabled {
        color: $BUTTON_SECRET_COLOR;
        border: 1px solid $BUTTON_SECRET_BORDER;
        background: $BUTTON_SECRET_BACKGROUND;
    }

    &:hover,
    &:focus {
        color: $BUTTON_SECRET_COLOR_HOVER;
        border-color: $BUTTON_SECRET_BORDER_HOVER;
        background-color: $BUTTON_SECRET_BACKGROUND_HOVER;
        text-decoration: underline;
    }

    &:active,
    &.is-active {
        color: $BUTTON_SECRET_COLOR_SELECTED;
        border-color: $BUTTON_SECRET_BORDER_SELECTED;
        background: $BUTTON_SECRET_BACKGROUND_SELECTED;
    }

    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_SECRET_COLOR;
        border-color: $BUTTON_SECRET_BORDER;
        background-color: $BUTTON_SECRET_BACKGROUND;
        box-shadow: none;
    }
}

.button__profile {
    color: $BUTTON_PRIMARY_COLOR;
    border: 2px solid $BUTTON_PRIMARY_BORDER;
    background: $BUTTON_PRIMARY_BACKGROUND;


    &:hover,
    &:focus {
        color: $BUTTON_PRIMARY_COLOR;
        border-color: $BUTTON_PRIMARY_BORDER_HOVER;
        background-color: $BUTTON_PRIMARY_BACKGROUND_HOVER;
    }

    &:active,
    &.is-active {
        color: $BUTTON_PRIMARY_COLOR_SELECTED;
        border-color: $BUTTON_PRIMARY_BORDER_SELECTED;
        background-color: $BUTTON_PRIMARY_BACKGROUND_SELECTED;
    }

    &:disabled, &.is-disabled,
    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_DISABLED_COLOR;
        border: 2px solid $BUTTON_DISABLED_BORDER;
        background: $BUTTON_DISABLED_BACKGROUND;
    }

    &__outline {
        color: $BUTTON_PRIMARY_OUTLINE_COLOR;
        border: 2px solid $BUTTON_PRIMARY_OUTLINE_BORDER;
        background: $BUTTON_PRIMARY_OUTLINE_BACKGROUND;

        &:hover,
        &:focus {
            color: $BUTTON_PRIMARY_OUTLINE_COLOR_SELECTED;
            border-color: $BUTTON_PRIMARY_OUTLINE_BORDER_HOVER;
            background-color: $BUTTON_PRIMARY_OUTLINE_BACKGROUND_HOVER;
        }

        &:active,
        &.is-active {
            color: $BUTTON_PRIMARY_OUTLINE_COLOR_SELECTED;
            border-color: $BUTTON_PRIMARY_OUTLINE_BORDER_SELECTED;
            background-color: $BUTTON_PRIMARY_OUTLINE_BACKGROUND_SELECTED;
        }
    }

     &.tooltip span {
        font-size: 16px;
        padding-top: 3px;
        display: block;
    }
}
.button-group {
    .button__profile {
        &.tooltip span {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }
}
.button__secondary {
    color: $BUTTON_COLOR;
    border: 2px solid $BUTTON_BORDER;
    background: $BUTTON_BACKGROUND;


    &:hover,
    &:focus {
        color: $white;
        border-color: $BUTTON_BORDER_HOVER;
        background-color: $BUTTON_BACKGROUND_HOVER;
    }

    &:active,
    &.is-active {
        color: $BUTTON_COLOR_SELECTED;
        border-color: $BUTTON_BORDER_SELECTED;
        background-color: $BUTTON_BACKGROUND_SELECTED;
    }

    &:disabled, &.is-disabled,
    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_DISABLED_COLOR;
        border: 2px solid $BUTTON_DISABLED_BORDER;
        background: $BUTTON_DISABLED_BACKGROUND;
    }
}

.button__primary {
    color: $BUTTON_PRIMARY_COLOR;
    border: 2px solid $BUTTON_PRIMARY_BORDER;
    background: $BUTTON_PRIMARY_BACKGROUND;


    &:hover,
    &:focus {
        color: $BUTTON_PRIMARY_COLOR;
        border-color: $BUTTON_PRIMARY_BORDER_HOVER;
        background-color: $BUTTON_PRIMARY_BACKGROUND_HOVER;
    }

    &:active,
    &.is-active {
        color: $BUTTON_PRIMARY_COLOR_SELECTED;
        border-color: $BUTTON_PRIMARY_BORDER_SELECTED;
        background-color: $BUTTON_PRIMARY_BACKGROUND_SELECTED;
    }

    &:disabled, &.is-disabled,
    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_DISABLED_COLOR;
        border: 2px solid $BUTTON_DISABLED_BORDER;
        background: $BUTTON_DISABLED_BACKGROUND;
    }

    &__outline {
        color: $BUTTON_PRIMARY_OUTLINE_COLOR;
        border: 2px solid $BUTTON_PRIMARY_OUTLINE_BORDER;
        background: $BUTTON_PRIMARY_OUTLINE_BACKGROUND;

        &:hover,
        &:focus {
            color: $BUTTON_PRIMARY_OUTLINE_COLOR_SELECTED;
            border-color: $BUTTON_PRIMARY_OUTLINE_BORDER_HOVER;
            background-color: $BUTTON_PRIMARY_OUTLINE_BACKGROUND_HOVER;
        }

        &:active,
        &.is-active {
            color: $BUTTON_PRIMARY_OUTLINE_COLOR_SELECTED;
            border-color: $BUTTON_PRIMARY_OUTLINE_BORDER_SELECTED;
            background-color: $BUTTON_PRIMARY_OUTLINE_BACKGROUND_SELECTED;
        }
    }
}

.button__lead {
    color: $BUTTON_EMPHASIS_COLOR !important;
    border: 2px solid $BUTTON_EMPHASIS_BORDER !important;
    background: $BUTTON_EMPHASIS_BACKGROUND !important;


    &:hover,
    &:focus {
        color: $BUTTON_EMPHASIS_COLOR !important;
        border-color: $BUTTON_EMPHASIS_BORDER_HOVER !important;
        background-color: $BUTTON_EMPHASIS_BACKGROUND_HOVER !important;
    }

    &:active,
    &.is-active {
        color: $BUTTON_EMPHASIS_COLOR_SELECTED !important;
        border-color: $BUTTON_EMPHASIS_BORDER_SELECTED !important;
        background-color: $BUTTON_EMPHASIS_BACKGROUND_SELECTED !important;
    }

    &:disabled, &.is-disabled,
    &:disabled:hover, &.is-disabled:hover,
    &:disabled:hover:hover, &.is-disabled:hover:hover {
        color: $BUTTON_DISABLED_COLOR;
        border: 2px solid $BUTTON_DISABLED_BORDER;
        background: $BUTTON_DISABLED_BACKGROUND;
    }
}



.toggle_buttons {
    .button {
        border-radius: 0;
        margin-left: -1px;
        border: none;
        background: none;

        &:hover {
            color: #008146;
        }

        .icon__arrow_right::before {
            font-size: 24px;
        }

        .icon__arrow_left::before {
            font-size: 24px;
        }
    }
}

.selector__button {
    display: inline-block;
    text-align: left;
}

.button__secret {
    box-shadow: inset 0 1px 0 0 #fff, 0 2px 2px 0 rgba(147,147,147,.1);
    color: #585858;
    font-weight: 400;
    background: 0 0;
    color: #5f5f5f;
    border: 1px solid #c4c4c4;
    text-decoration: none;
    box-shadow: none;
    border-color: transparent;
}

.slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;

    li {
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 5px;
        padding: 0;
        cursor: pointer;

        &.slick-active button {
            background: #000;
            opacity: .75;
        }

        button {
            border: 0;
            background: #000;
            display: block;
            height: 10px;
            width: 10px;
            border-radius: 5px;
            padding: 5px;
            outline: 0;
            font-size: 0;
            color: transparent;
            cursor: pointer;
            opacity: .25;
        }
    }
}

.js-compare-button {
    color: $BUTTON_EMPHASIS_COLOR;
    border: 2px solid $BUTTON_EMPHASIS_BORDER;
    background: $BUTTON_EMPHASIS_BACKGROUND;
}
