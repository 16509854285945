.profile_header {
    margin-bottom: 64px;
    background-color: #f2f5f5;
    border-bottom: 8px solid #e2e2e2;

    .js-scroll-show {
        display: none;
    }
    &.is-fixed {
        position: fixed;
        z-index: 1010;
        width: 100%;
        top: 0;

        .card__image {
            margin-right: 16px;

            .stats {
                display: none;
            }
        }

        .tertiary-nav--link {
            padding: 10px 16px;
        }

        .profile_header--content {
            overfow: hidden;
            padding-top: 5px;
            padding-bottom: 0;
            box-shadow: none;
            background-image: none;
        }
        .id__sq_large {
            width: 60px;
            height: 60px;
            font-size: 48px;
            transition: all .1s linear;
        }
        .ribbon {
            top: 8px;
            right: -60px;
            font-size: 8px;
            line-height: 1;
            padding: 4px;
        }
        .profile_header--title {
            font-size: 18px;
        }
        .tertiary-nav--items {
            border-bottom: none;
        }
        .badges {
            display: none;
        }
        .js-scroll-hide {
            display: none;
        }
        .js-scroll-show {
            display: initial !important;
        }
    }
    @include media-breakpoint-down(xm) {
        margin-bottom: 24px;

        &--navigation {
            padding-left: 12px;
            padding-right: 12px;
        }
        .id__sq_large {
            width: 72px;
        }
    }

    .stats {
        text-align: center;
        margin-top: 8px;
        color: $text-light;

        .rating {
            display: inline-block;
        }

        .num-rating {
            vertical-align: top;
            line-height: 1.4;

            @include media-breakpoint-down(xm) {
                display: none;
            }
        }
    }

    .summary--caption {
        margin: 8px 0 16px;
    }
}

.profile_demo_iframe {
    width: 100%;
    height: 601px;
}

.profile_header--content {
    border-top: 1px solid lightgray;
    padding-top: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 24px 12px 0;
    }
}

.card__actions {
    position: absolute;
    text-align: right;
    max-width: 50%;
    top: -4px;
    right: 0;
    @include media-breakpoint-up(lg) {
        max-width: 60%;
    }
    .button__profile {
        margin-left: 0;
        margin-top: 4px;
    }
}

.card__caption {
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    &.tooltip {
        span {
            cursor: pointer;
        }
    }
    a {
        color: #fff;
    }
}

.card__title {
    color: #fff;
    font-size: 12px;
    padding: 15px 0 0 0;
}


.profile_header--title {
    margin-bottom: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    font-size: 40px;
    margin-bottom: 8px;

    @include media-breakpoint-down(xm) {
        font-size: 22px;
    }

}

.profile-feature {
    &--image {
        margin-bottom: 24px;
        & img {
            max-width: 100%; /* making sure images don't go all over the place on mobile */
        }
    }
    &--characteristics {
        & ul {
            margin-bottom: 24px;
        }
    }
    &--buttons {
        display: flex;
        justify-content: space-between;
    }
}
