$text-color: $black;
$text-color-selected: $black;
$type-family-title: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$bg-color: #f5f5f5;
$bg-color-selected: #575757;

.secondary_nav {
    font: normal normal normal 16px/1.5 'Sage Text', 'Helvetica Neue', Roboto, Arial, Sans-Serif;
    border-bottom: none;
    background-color: $bg-color;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.24);
    height: 54px;
    
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.secondary_nav--items {
    font-size: 0;
    margin: {
        top: 0;
        bottom: 0;
    }
}

.secondary_nav--item {
    margin-right: 40px;
    list-style: none;
    display: inline-block;
}

.secondary_nav--item__right {
    float: right;
    margin-right: 0;
}

.secondary_nav--link {
    display: block;
    font-size: 16px;
    line-height: 28px;
    text-decoration: none;
    padding: 13px 0;
    color: $text-color;
    background-color: transparent;
    position: relative;
    transition-property: color, background;
    transition-duration: .1s;
    transition-timing-function: linear;

    &:focus,
    &:hover {
        color: #009900;
        text-decoration: none;
    }

    &.is-selected {
        font-weight: 700;
        color: $text-color-selected;
    }
}

.secondary_nav--item__breadcrumb {
    .secondary_nav--content {
        color: mix($bg-color, $text-color, 40%);
        font: bold 12px/28px $type-family-title;
        text-transform: uppercase;

        &:focus,
        &:hover {
            color:  $text-color-selected;
            text-decoration: none;
        }
    }

    @include adb-breakpoint(root) {
        padding-left: 24px;
    }

    .secondary_nav--link {
        @extend .secondary_nav--content;

        // State for when we're on the same page as the link
        &.is-selected {
            position: relative;
            &:before {
              background-color: $bg-color-selected;
              bottom: -1 * $border-width;
              content: '';
              height: 2px;
              left: 0;
              position: absolute;
              width: 100%;
            }
        }
    }
}
