$slider-tile-width-narrow: 264px;

.bundle-slider {
    position: relative;
    overflow: hidden;
    margin-bottom: -3px;
}

.bundle-slider {
	
	&--item {
        vertical-align: middle;
        float: left;
        min-height: 1px;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 24px;
        position: relative;
        max-width: $slider-tile-width-narrow;
        width: 100%;
        &:first-child {
            margin-left: 0;
        }
    }
}

.bundle-container {
    text-align: center;
}

.bundle-slider--item_content--headline {
    height: 38px;
    overflow: hidden;
}
.bundle-slider--item_content {
    margin: 6px 0;
}

.group-bundle {
    display: block;
    text-align: center;
}

.bundle-id--img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    margin: auto;
    z-index: inherit;
    border-radius: 6%;
    max-width: 100%;
    max-height: 100%;
}

.group-bundle-item {
    border-radius: 6px;
    display: inline-block;
    vertical-align: middle;
    font-size: 72px;
    width: 72px;
    height: 72px;
}

.bundle-container {
    text-align: center;
    margin-bottom: 1em;
}

.group-bundle {
    text-align: center;
    color: inherit;
    border-radius: 6px;
    padding: 14px;
    background-color: #f5f5f5;
}

.group-bundle--overlapping .group-bundle-item + .group-bundle-item {
    margin-left: -22px;
}

.group-bundle--overlapping .group-bundle-item:before {
    background-image: 
        linear-gradient (
            rgba(45, 45, 45, 0),
            rgba(45, 45, 45, 0.02)
        ),
        linear-gradient (
            to right,
            rgba(45, 45, 45, 0) 0,
            rgba(45, 45, 45, 0) 60%,
            rgba(45, 45, 45, 0.2) 100%
        );
}

.group-bundle--overlapping .group-bundle-item:last-child:before {
    background-image: linear-gradient (
        rgba(45, 45, 45, 0),
        rgba(45, 45, 45, 0.02)
    );
}
