.features-desc {
    margin-top: 0;
    min-height: 3.5em;
}

.features-container {
    margin-bottom:1em;
    padding: 5px 0 5px 15px;
}

.feature-actions {
    border-radius: 3px;
    margin-bottom: 24px;
    padding: 14px;
    /*background-color: #f5f5f5;*/
    &.center {
        text-align:center;
    }
}

.feature-actions-text {
    padding: 7px 0;
    text-align: left;
    display: inline-block;
    vertical-align: top;
    min-width: 70%;
    flex: 1 0 1px;
}
.feature-actions-button {
    padding: 7px 0;
    display: inline-block;
    vertical-align: top;
}

.price--label {
    color: #009abf;
}

.price--value {
    font-weight: bold;
}

.price--unit {
    text-transform: lowercase;
}

.caption {
    font-size: 14px;
    line-height: 1.6em;
    white-space: nowrap;
}
.caption-element:not(.is-hidden) + .caption-element:before {
    content: "·";
    display: inline-block;
    padding: 0 3px 0 2px;
}

.tile {
    width: 24%;
    display: inline-block;
    vertical-align: top;
    padding: 14px;
    text-align: center;
    @include media-breakpoint-down(xm) {
        width: calc(24% - 1px);
    }
    &:nth-child(4n + 1) {
        padding-right: 0;
    }
    &:nth-child(4n - 2) {
        padding-left: 0;
    }
    img {
        margin: auto;
        max-width: 100%;
        display:block;
    }
}
.modal__profile {
    max-width: 552px;
}

#profile-screenshots {
    max-width: 782px;
}

#faq-container {
    h2 {
        margin-right: 80px;
    }
}

.profile-content {
    .aside-top-wrapper {
        margin: 0 0 40px;

        .stack-count {
            color: inherit;
            font-size: inherit;
        }

        .stack--affix {
            position: static;

            &::before {
                content: "(";
            }

            &::after {
                content: ")";
            }
        }

        .link__nav {
            color: $black;
            line-height: 1.5;
            text-decoration: underline;

            &:hover,
            &:hover .sg-icon {
                color: $black;
                text-decoration: none;
            }
        }

        .sg-icon {
            color: $black;
            margin-right: 8px;
            font-size: 16px;
            vertical-align: text-top;
        }
    }

    .social-media-icons {
        justify-content: left;
        display: flex;
        margin: 16px 0 0;

        li {
            margin-right: 8px;
        }

        a {
            padding: 8px 10px;
            text-decoration: none;
            color: $black;

            &:hover {
                background: $black;
                color: $white;

                .sg-icon {
                    color: inherit;
                }
            }
        }

        .sg-icon {
            margin-right: 0;
        }
    }

    .button-group {
        margin-bottom: 40px;

        .button {
            margin: 0 16px 16px 0;
        }

        @media (max-width: 600px) {
            .button {
                width: 100%;
                margin: 0 0 16px;
            }
        }
    }

    .profile-overview .button-group {
        margin-top: 24px;
    }
}

.profile_header.is-fixed .profile_header--navigation {
    background-color: transparent;
    box-shadow: none;
    border-bottom: none;

    .tertiary-nav--link:before {
        bottom: 0px;
    }
}
