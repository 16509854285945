// button specific colors

$BUTTON_COLOR: #000000;
$BUTTON_COLOR_SELECTED: #ffffff;
$BUTTON_BORDER: #000000;
$BUTTON_BORDER_HOVER: #303030;
$BUTTON_BORDER_SELECTED: #5E5E5E;
$BUTTON_BACKGROUND: transparent;
$BUTTON_BACKGROUND_HOVER: #303030;
$BUTTON_BACKGROUND_SELECTED: #5E5E5E;

$BUTTON_PRIMARY_COLOR: #ffffff;
$BUTTON_PRIMARY_COLOR_SELECTED: #ffffff;
$BUTTON_PRIMARY_BORDER: #008146;
$BUTTON_PRIMARY_BORDER_HOVER: #006738;
$BUTTON_PRIMARY_BORDER_SELECTED: #004D2A;
$BUTTON_PRIMARY_BACKGROUND: #008146;
$BUTTON_PRIMARY_BACKGROUND_HOVER: #006738;
$BUTTON_PRIMARY_BACKGROUND_SELECTED: #004D2A;


$BUTTON_PRIMARY_OUTLINE_COLOR: #008146;
$BUTTON_PRIMARY_OUTLINE_COLOR_SELECTED: #ffffff;
$BUTTON_PRIMARY_OUTLINE_BORDER: #008146;
$BUTTON_PRIMARY_OUTLINE_BORDER_HOVER: #006738;
$BUTTON_PRIMARY_OUTLINE_BORDER_SELECTED: #004D2A;
$BUTTON_PRIMARY_OUTLINE_BACKGROUND: transparent;
$BUTTON_PRIMARY_OUTLINE_BACKGROUND_HOVER: #006738;
$BUTTON_PRIMARY_OUTLINE_BACKGROUND_SELECTED: #004D2A;


$BUTTON_EMPHASIS_COLOR: #ffffff;
$BUTTON_EMPHASIS_COLOR_SELECTED: #ffffff;
$BUTTON_EMPHASIS_BORDER: #000000;
$BUTTON_EMPHASIS_BORDER_HOVER: #5E5E5E;
$BUTTON_EMPHASIS_BORDER_SELECTED: #5E5E5E;
$BUTTON_EMPHASIS_BACKGROUND: #000000;
$BUTTON_EMPHASIS_BACKGROUND_HOVER: #5E5E5E;
$BUTTON_EMPHASIS_BACKGROUND_SELECTED: #5E5E5E;


$BUTTON_DANGER_COLOR: #ffffff;
$BUTTON_DANGER_COLOR_SELECTED: #ffffff;
$BUTTON_DANGER_BORDER: #CD384B;
$BUTTON_DANGER_BACKGROUND: #CD384B;
$BUTTON_DANGER_BACKGROUND_HOVER: darken($BUTTON_DANGER_BACKGROUND, 5%);
$BUTTON_DANGER_BACKGROUND_SELECTED: darken($BUTTON_DANGER_BACKGROUND, 10%);
$BUTTON_DANGER_BORDER_HOVER: darken($BUTTON_DANGER_BACKGROUND, 5%);
$BUTTON_DANGER_BORDER_SELECTED: darken($BUTTON_DANGER_BACKGROUND, 10%);



$BUTTON_INSET_COLOR: #8e8e8e;
$BUTTON_INSET_COLOR_HOVER: #0a0a0a;
$BUTTON_INSET_COLOR_SELECTED: #0a0a0a;
$BUTTON_INSET_TEXT_SHADOW: #ffffff;
$BUTTON_INSET_TEXT_SHADOW_SELECTED: #f5f5f5;
$BUTTON_INSET_BORDER: #cacaca;
$BUTTON_INSET_BORDER_HOVER: #a9a9a9;
$BUTTON_INSET_BORDER_SELECTED: #a9a9a9;
$BUTTON_INSET_BACKGROUND: transparent;
$BUTTON_INSET_BACKGROUND_HOVER: #ffffff;
$BUTTON_INSET_BACKGROUND_SELECTED: #ffffff;
$BUTTON_INSET_BOX_SHADOW: #ffffff;
$BUTTON_INSET_BOX_SHADOW_2: #ffffff;
$BUTTON_INSET_BOX_SHADOW_HOVER: #ffffff;
$BUTTON_INSET_BOX_SHADOW_HOVER_2: rgba(147, 147, 147, 0.4);
$BUTTON_INSET_BOX_SHADOW_SELECTED: rgba(143, 143, 143, 0.4);
$BUTTON_INSET_BOX_SHADOW_SELECTED_2: #ffffff;

$BUTTON_INSET_DARK_COLOR: #d2d2d2;
$BUTTON_INSET_DARK_COLOR_HOVER: #d2d2d2;
$BUTTON_INSET_DARK_COLOR_SELECTED: #d2d2d2;
$BUTTON_INSET_DARK_TEXT_SHADOW: #000000;
$BUTTON_INSET_DARK_TEXT_SHADOW_SELECTED: #000000;
$BUTTON_INSET_DARK_BORDER: #2d2d2d;
$BUTTON_INSET_DARK_BORDER_HOVER: #000000;
$BUTTON_INSET_DARK_BORDER_SELECTED: #000000;
$BUTTON_INSET_DARK_BACKGROUND: transparent;
$BUTTON_INSET_DARK_BACKGROUND_HOVER: #535353;
$BUTTON_INSET_DARK_BACKGROUND_SELECTED: rgba(0, 0, 0, 0.2);
$BUTTON_INSET_DARK_BOX_SHADOW: #606060;
$BUTTON_INSET_DARK_BOX_SHADOW_2: #606060;
$BUTTON_INSET_DARK_BOX_SHADOW_HOVER: #606060;
$BUTTON_INSET_DARK_BOX_SHADOW_HOVER_2: rgba(27, 27, 27, 0.4);
$BUTTON_INSET_DARK_BOX_SHADOW_SELECTED: rgba(0, 0, 0, 0.4);
$BUTTON_INSET_DARK_BOX_SHADOW_SELECTED_2: #606060;

$BUTTON_SECRET_COLOR: #008146;
$BUTTON_SECRET_COLOR_HOVER: #006738;
$BUTTON_SECRET_COLOR_SELECTED: #004D2A;
$BUTTON_SECRET_TEXT_SHADOW: transparent;
$BUTTON_SECRET_TEXT_SHADOW_SELECTED: transparent;
$BUTTON_SECRET_BORDER: transparent;
$BUTTON_SECRET_BORDER_HOVER: transparent;
$BUTTON_SECRET_BORDER_SELECTED: transparent;
$BUTTON_SECRET_BACKGROUND: transparent;
$BUTTON_SECRET_BACKGROUND_HOVER: transparent;
$BUTTON_SECRET_BACKGROUND_SELECTED: transparent;
$BUTTON_SECRET_BOX_SHADOW_HOVER: transparent;
$BUTTON_SECRET_BOX_SHADOW_HOVER_2: transparent;
$BUTTON_SECRET_BOX_SHADOW_SELECTED: transparent;
$BUTTON_SECRET_BOX_SHADOW_SELECTED_2: transparent;

$BUTTON_DISABLED_COLOR: #303030;
$BUTTON_DISABLED_BACKGROUND: #e2e2e2;
$BUTTON_DISABLED_BORDER: #303030;