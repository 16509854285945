#header-universal {
    min-height: 64px;
    background-color: #000;
    box-shadow: 0 1px 0 0 rgba(0,0,0,.24);
}

.header {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
}

.header--item {
    display: inline-block;
    margin-bottom: 0;
    &:first-child {
        flex: 1 0 1px;
    }
    
}
h2.header--item {
    font-size: 24px;
}