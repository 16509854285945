.hero {
    width: 100%;
    position: relative;
    overflow: hidden;

    &__container {
        overflow: hidden;
        height: 300px;
        position: relative;
        border-radius: 24px;

        @include media-breakpoint-down(xm) {
            border: 0;
        }
    }

    &__carousel__container {
        z-index: 1;
        position: relative;
        left: 50%;
        margin-left: -50%;
        max-width: 100%;

        @include media-breakpoint-down(md) {
            margin-left: -50%;
        }
    }

    &__item {
        transition: all .2s ease-in;
        width: 864px;
        float: left;
        position: relative;
        left: 0;
        right: 0;

        &.prev-selected,
        &.next-selected {
            background: #fff;

            .hero__item_card:before {
                opacity: 0;
            }
        }
    }

    &__item_card {
        position: relative;
        margin: 0;
        border-radius: 24px;
        background-color: $gray-t6;
        transition: all .2s ease-in;
        height: 300px;
        padding: 24px;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;

        @include media-breakpoint-down(xm) {
            flex-direction: column;
            margin: 0;
            border: 0;
            padding: 0;
        }

        &:before {
            transition: all .2s ease-in;
            border-radius: 3px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: block;
            content: '';
            background-color: #f5f5f5;
            opacity: .6;
            z-index: 3;
        }
    }

    &__item_content,
    &__item_image {
        width: 50%;
        padding-right: 24px;

        @include media-breakpoint-down(xm) {
            padding-right: 0;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;

            .title__xxlarge {
                font: bold 20px/1.4 "Helvetica Neue",Helvetica,Arial,sans-serif;
            }

            p {
                margin: 0.5em 0;
            }

            .button__large {
                font-size: 12px;
                height: 28px;
                padding: 4px 8px;
            }

            img {
                max-height: 180px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        &__item_image {
            margin-bottom: 10px;
        }
    }

    &__carousel-nav {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;

        &__prev, &__next {
            list-style: none;

            &::before {
                content: "";
                position: absolute;
                height: 100%;
                left: 0;
                z-index: 2;

                @include media-breakpoint-down(xm) {
                    content: none;
                }
            }

            &__link {
                transition: all .2s ease-in;
                background-color: transparent;
                position: absolute;
                top: 50%;
                bottom: 0;
                width: 40px;
                height: 64px;
                display: block;
                z-index: 3;
                margin-top: -32px;

                &:before {
                    position: absolute;
                    display: block;
                    font-family: SageUIIcons;
                    content: "#{$sg-icon-chevron-left}";
                    top: 21px;
                    height: 80%;
                    width: 100%;
                    left: 8px;
                    font-size: 24px;
                    color: $black;

                    @include media-breakpoint-down(xs) {
                        box-shadow: none;
                    }
                }

                &:hover {
                    &:before {
                        color: $white;
                    }
                }
            }
        }

        &__prev {
            &__link {
                &:before {
                    left: 1px;
                }
            }
        }

        &__next {
            &::before {
                border-left: 0;
                left: auto;
                right: 0
            }

            &__link {
                right: 0;

                &:before {
                    content: "#{$sg-icon-chevron-right}";
                    left: 14px;
                }
            }
        }
    }

    .app-icon {
        max-width: 64px;
        display: block;
        margin-bottom: 16px;
    }
}


@include media-breakpoint-down(xm) {
    .hero__carousel-nav__next__link, .hero__carousel-nav__prev__link {
        display: none !important;
    }
}


.hero__item.slick-active .hero__item_card:before {
    opacity: 0;
    display: none;
}
