.tooltip--complex {
    position: relative;
    width: 120px;
    height: 120px;

    &__content {
        font-size: 12px;
        visibility: hidden;
        background-color: #121212;
        text-align: left;
        padding: 20px 24px 24px;
        line-height: 1.6em;
        opacity: 0;
        transition: opacity 0.25s;
        text-shadow: none;
        width: 264px;
        box-shadow: 0 3px 10px 0 rgba(45, 45, 45, .125);
        position: absolute;
        z-index: 11;
        left: 50%;
        word-wrap: break-word;
        color: $white;
    
        &--bottom, &--top {
            &:before, &:after {
                content: "";
                position: absolute;
                left: 50%;
                margin-left: -8px;
                border-width: 8px;
                border-style: solid;
            }
        }

        &--bottom {
            top: 115%;
            &:before {
                bottom: 100%;
                border-color: transparent transparent #121212 transparent;
            }
            &:after {
                top: -15px;
                border-color: transparent transparent #121212 transparent;
            }
        }

        &--top {
            bottom: 110%;
            &:before {
                top: 100%;
                border-color: #121212 transparent transparent transparent;
            }
            &:after {
                bottom: -15px;
                border-color: #121212 transparent transparent transparent;                
            }
        }

        .tooltip--complex__overview {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 110px;
        }

        h2, h3, h4 {
            color: inherit;
        }

        .subtitle {
            color: #999;

            &:empty {
                display: none;
            }
        }
    }

    .rating {
        display: inline-block;
    }
    
    .num-rating {
        vertical-align: top;
    }
}

@keyframes top {
    0% {
        transform: translateY(-20px);
    }
    90% {
        transform: translateY(2px);
    }
}

@keyframes bottom {
    0% {
        transform: translateY(20px);
    }
    90% {
        transform: translateY(-2px);
    }
}

.tooltip--complex.is-active .tooltip--complex__content {
    opacity: 1;
    visibility: visible;

    &.tooltip--complex__content--top {
        animation: top .25s;
    }
    &.tooltip--complex__content--bottom {
        animation: bottom .25s;
    }
}

.tooltip--complex--global {
    display: none;
}
